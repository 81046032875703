import { useState, useEffect } from 'react' // Import useState and useEffect
import { useNavOpen } from '../store'
import WithApp from '../../_utils/withApp.tsx'
import { Context } from '../../_types'
import { isClient } from '../../_utils'
import Menu from './icons/menu.tsx'

const scrollbar = isClient ? window.innerWidth - document.documentElement.clientWidth : 0

function Component() {
  const { setOpen, open } = useNavOpen()
  const [isVisible, setIsVisible] = useState(true)
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [scrollThreshold, setScrollThreshold] = useState(0)

  const padding = 42 + -(open ? scrollbar : 0) / 2

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset
      const threshold = 15

      if (prevScrollPos > currentScrollPos) {
        setIsVisible(true)
      } else if (currentScrollPos - scrollThreshold > threshold) {
        setIsVisible(false)
      }

      setPrevScrollPos(currentScrollPos)
      setScrollThreshold(currentScrollPos)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [prevScrollPos, scrollThreshold])

  return (
    <div
      className={`fixed bottom-12 right-[50%] transform no-print ${isVisible ? '' : 'opacity-0'}`}
      style={{
        transform: `translateX(${padding}px)`,
        transition: 'opacity 0.3s ease-in-out',
      }}>
      <span
        className="block cursor-pointer rounded-full bg-slate-300 px-5 py-2 hover:bg-slate-400 md:hidden border bolder-solid border-black"
        onClick={() => {
          setOpen(true)
        }}>
        <Menu fullClass="w-10 h-10" />
      </span>
    </div>
  )
}

export default function MobileMenu({ context }: { context: Context }) {
  return (
    <WithApp context={context}>
      <Component />
    </WithApp>
  )
}
